import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { Loader } from '../Loader/Loader';

import './Input.css'

const InputImage = ({ label, labelRsl, width = 'auto', height = 'auto', urlImage = '', setUrlImage = (() => null),
    onChange, setInputFiles, InputFiles, fetchAccionesData, handleSubirImagen, handleEliminarImagen, onlyRead }) => {

    const inputFileRef = useRef(null)

    useEffect(() => {

        // console.log(urlImage)
        if (urlImage) {
            setInputFiles([
                {
                    path: urlImage
                }
            ])
        }

    }, [urlImage])


    const onFileChange = (e) => {

        console.log(e.target.files)

        const newFiles = e.target.files

        // if (handleSubirImagen) {

        //     handleSubirImagen(newFiles[0])
        // }

        setInputFiles([
            ...InputFiles,
            ...newFiles
        ])

    }

    const handleClickInputFile = () => {
        inputFileRef.current.click();
    }

    const handleClickRemoveFile = (index) => {

        let InputFilesTemp = [...InputFiles]

        if (handleEliminarImagen) {

            handleEliminarImagen()
        }

        InputFilesTemp.splice(index, 1)

        setInputFiles([
            ...InputFilesTemp
        ])
        inputFileRef.current.value = null
        setUrlImage('')
    }

    return (
        <div className='InputImages d-flex flex-column' style={{ 'width': width, 'height': 'auto' }}>
            {
                label && <Form.Group controlId="formFile" className="d-flex justify-content-between">
                    <Form.Label>{label}</Form.Label>
                </Form.Group>
            }
            <Form.Control type="file" accept="image/png, .jpeg, .jpg, image/gif" ref={inputFileRef} onChange={(e) => {
                if (onChange) {
                    onChange(e)
                } else {
                    onFileChange(e)
                }
            }} className='d-none' />
            <div className="InputImage__container d-flex flex-wrap container-files-input mb-4 justify-content-center flex-column align-items-center">
                {
                    InputFiles.map((item, index) => {
                        let src = item?.path
                        if (!src) {
                            // console.log(item)
                            src = URL.createObjectURL(item)
                        }
                        return (
                            <>{
                                onlyRead ? <div className='box-input-file-elem m-0 p-0 box-input-file-elem-nohover' key={'2' + index} style={{ 'width': '100%', 'height': height }}>
                                    <img src={src} alt="" style={{maxWidth: '100%', maxHeight: '100%'}} />
                                </div> :
                                    <div className='box-input-file-elem m-0 p-0' key={'2' + index} style={{ 'width': '100%', 'height': height }}>
                                        <img src={src} alt="" style={{maxWidth: '100%', maxHeight: '100%'}} />
                                        <div className='box-input-file-elem-options'>
                                            <div className='box-input-file-elem-option elem-option-1 text-danger' onClick={() => handleClickRemoveFile(index)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </div>
                                        </div>
                                    </div>
                            }
                            </>

                        )
                    })
                }
                {
                    onlyRead ?
                        (
                            InputFiles.length === 0 && <div style={{ 'width': '100%', minHeight: height }}
                                className='InputImage__input box-input-file box-input-file-onlyread d-inline-flex flex-column justify-content-center align-items-center'
                            >
                            </div>
                        ) :
                        (
                            InputFiles.length === 0 && <div style={{ 'width': '100%', minHeight: height }}
                                className='InputImage__input box-input-file d-inline-flex flex-column justify-content-center align-items-center'
                                onClick={handleClickInputFile}>
                                <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                                <span className='pt-3 text-center'>Agregar una imagen</span>
                            </div>
                        )

                }
                {
                    labelRsl && <strong className='text-center pt-3'>{labelRsl}</strong>
                }


            </div>
            {
                fetchAccionesData && fetchAccionesData.loading && <div className='InputImages__Containerloader d-flex align-items-center justify-content-center'>
                    <Loader />
                </div>
            }
        </div>

    )
}


export default InputImage