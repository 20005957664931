import { faBars, faCheckCircle, faSave, faUserCircle, faBell, faEye, faTimes, faUserTie, faNewspaper, faExclamationCircle, faTicketAlt, faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Dropdown, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { getMsgInputColor, getMsgInputServerColor, isConsultor, isVerificador, isOrganizacion, isModuleAdministrador } from '../../helpers/helpers';
import { images } from '../../helpers/images';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { types } from '../../types/types';
import { MsgInput } from '../MsgInput/MsgInput';
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import { useLang } from '../../language/';

import './NavtopInner.css'
import { useAxios } from '../../hooks/useAxios';
import { InputText } from '../Inputs';
import { ModalStatus } from '../ModalStatusServer/ModalStatus';
import { useModalData } from '../../hooks/useModalData';
import {ModalDetalle} from '../../pages/Contenidos/ContenidosPublic/ModalDetalle'
import { useNotifications } from '../../auth/NotificationContext';

export const NavtopInner = ({ setClose, close }) => {

    const { user: { nombre, apellidos, lang, perfil, modulo_app, token }, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const location = useLocation();

    const isActive = location.pathname === '/tickets';

    const [fetchLogout, fetchLogoutData] = useAxios('delete', `/auth/logout`)

    const [dataModal_Detalle, handleOpenModal_Detalle, handleCloseModal_Detalle] = useModalData()

    const Lang = useLang();

    const handleLogout = (e) => {

        e.preventDefault()

        fetchLogout()

        history.replace('/login');

        dispatch({
            type: types.logout,
            lang
        });
    }

    const [showModalChangePassword, setShowModalChangePassword] = useState(false);

    const handleClose = () => setShowModalChangePassword(false);
    const handleShow = () => setShowModalChangePassword(true);

    useEffect(() => {
        if (!showModalChangePassword) {
            resetFormValuesPassword()
            resetValuesValidPassword()
        }
    }, [showModalChangePassword])

    const [fetchPassword, fetchPasswordData] = useAxios('patch', `/usuarios/cambiarPassword`)

    const [formValuesPassword, handleInputChangePassword, handleFormChangePassword, resetFormValuesPassword] = useForm({
        old_password: '',
        new_password: '',
        new_password_confirmation: ''
    })

    const [valuesValidPassword, handleVerifiedValuesPassword, resetValuesValidPassword] = useValidator({
        old_password: { isOk: true, msgError: [`La contraseña antigua es obligatoria.`] },
        new_password: { isOk: true, msgError: [`La contraseña nueva es obligatoria.`] },
        new_password_confirmation: { isOk: true, msgError: [`La contraseña nueva es obligatoria.`] }
    })

    const handleSubmitCambiarPassword = (e) => {

        if (handleVerifiedValuesPassword(formValuesPassword)) {

            console.log(formValuesPassword)
            fetchPassword({
                body: {
                    ...formValuesPassword
                }
            })
        }
    }

    // useEffect(() => {

    //     if (fetchPasswordData.data?.status === 'Success') {
    //         console.log('success')
    //         handleClose()
    //     }

    // }, [fetchPasswordData])

    const handleClickMenuIcon = () => {

        setClose(!close)
    }


    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchPasswordData.data)
        if (fetchPasswordData.data?.status === 200) {
            setShowSuccessEdit(true)
            console.log('success')
            handleClose()
        }

    }, [fetchPasswordData])

    //const [fetchNotificaciones, fetchNotificacionesData] = useAxios('GET', `/obtener-notificaciones`)
    const { memoizedFetchNotificaciones, fetchNotificacionesData } = useNotifications();
    const [hasLoadedNotifications, setHasLoadedNotifications] = useState(false);

    useEffect(() => {
        if(isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil) && !hasLoadedNotifications){
            memoizedFetchNotificaciones()
            console.log('renderizo');
            setHasLoadedNotifications(true);
        }
    }, [perfil, hasLoadedNotifications])

    const redirectForum = (e) => {
        e.preventDefault();
        const forum = process.env.REACT_APP_FORUM;
        const link = forum+'/login_token?token='+token;
        window.open(link, '_blank', 'noopener,noreferrer');
    }
    const [activeKey, setActiveKey] = useState('comunicados');
    const handleShowTicker = (id) => {
        history.push('/tickets/'+id);
    };  

    return (
        <>
        <section className="NavtopInner d-flex justify-content-between align-items-center">
            <div className="fw-bold">
                <h5 className="my-0">
                    <div className="d-flex align-items-center justify-content-center" id='container-logo-inner'>
                        {/*<FontAwesomeIcon icon={faBars} className='me-3' id='icon-bars' onClick={handleClickMenuIcon} />*/}
                        <img src={images('./logo-inner.png')} className="mx-auto d-block me-2" alt="logo" id="logo-inner" />
                        
                        <button onClick={handleClickMenuIcon} className="d-md-none">
                            <FontAwesomeIcon icon={faBars} className={'me-1 text-dark'} />
                        </button>
    
                        {/* <strong className="fw-500 d-none d-md-block">DAPcons</strong> */}
                    </div>
                </h5>

            </div>

            <div className="d-flex align-items-center" id=''>
                {(isVerificador(perfil) || isModuleAdministrador(modulo_app)) &&
                    <>
                        <FontAwesomeIcon icon={faUserTie} className='me-2' style={{fontSize: '20px'}} />
                        <span className="me-2" onClick={redirectForum} style={{cursor:'pointer'}}>
                           Forum
                        </span>
                    </>
                }

                {!isModuleAdministrador(modulo_app) &&

                <Dropdown align="end">
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="d-flex align-items-center">
                   <FontAwesomeIcon 
                        icon={faBell} 
                        className={`me-2 ${fetchNotificacionesData.data?.data?.data?.tickets?.total > 0 || fetchNotificacionesData.data?.data?.data?.comunicados?.total > 0 ? 'text-danger' : ''}`} 
                      />
                      <span className={`ms-2 ${fetchNotificacionesData.data?.data?.data?.tickets?.total > 0 || fetchNotificacionesData.data?.data?.data?.comunicados?.total > 0 ? 'text-danger' : ''}`}>
                        Notificaciones
                      </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="text-end shadow-sm" style={{ width: '380px' }}>
                  <Tabs
                    activeKey={activeKey}
                    onSelect={(k) => setActiveKey(k)}
                    id="comunicados-tickets-tabs"
                    className="w-100 text-center"
                  >
                    <Tab
                      eventKey="comunicados"
                      title={
                        <div
                          className={`btn btn-block btn-sm ${activeKey === 'comunicados' ? 'tab-active' : ''}`}
                        >
                          <FontAwesomeIcon
                            icon={faNewspaper}
                            className={`me-2 fs-6 ${activeKey === 'comunicados' ? 'text-white' : 'text-muted'}`}
                          />
                          Comunicados ({fetchNotificacionesData.data?.data?.data?.comunicados?.total || 0})
                        </div>
                      }
                    >
                      <div>
                        {fetchNotificacionesData.data?.data?.data?.comunicados?.data.length > 0 ? (
                          fetchNotificacionesData.data?.data?.data?.comunicados?.data.map((item, index) => (
                            <Dropdown.Item
                              className="text-start text-muted"
                              key={index}
                              onClick={() => handleOpenModal_Detalle({ id: item.id })}
                              style={{
                                display: 'block',
                                width: '100%',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal'
                              }}
                            >
                              <small className="d-block">{index + 1}. {item.titulo}</small>
                            </Dropdown.Item>
                          ))
                        ) : (
                          <p className="text-center mt-2 text-muted">
                            No hay comunicados disponibles.
                          </p>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          className="text-center text-dark"
                          onClick={() => history.push('/public-comunicados')}
                        >
                          Ver Comunicados
                        </Dropdown.Item>
                      </div>
                    </Tab>

                    <Tab
                      eventKey="tickets"
                      title={
                        <div
                          className={`btn btn-block btn-sm ${activeKey === 'tickets' ? 'tab-active' : ''}`}
                        >
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className={`me-2 fs-6 ${activeKey === 'tickets' ? 'text-white' : 'text-muted'}`}
                          />
                          Incidencias ({fetchNotificacionesData.data?.data?.data?.tickets?.total || 0})
                        </div>
                      }
                    >
                      <div>
                        {fetchNotificacionesData.data?.data?.data?.tickets?.data.length > 0 ? (
                          fetchNotificacionesData.data?.data?.data?.tickets?.data.map((item, index) => (
                            <Dropdown.Item
                              className="text-start text-muted"
                              key={index}
                              onClick={() => handleShowTicker(item.ticket_id)}
                              style={{
                                display: 'block',
                                width: '100%',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal'
                              }}
                            >
                              <small className="d-block">{index + 1}. {item.mensaje}</small>
                            </Dropdown.Item>
                          ))
                        ) : (
                          <p className="text-center mt-2 text-muted">
                            No hay incidencias disponibles.
                          </p>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          className="text-center text-dark"
                          onClick={() => history.push('/tickets')}
                        >
                          Ver Incidencias
                        </Dropdown.Item>
                      </div>
                    </Tab>
                  </Tabs>
                </Dropdown.Menu>
                </Dropdown>
            
                }

                {/*
                <Dropdown align="end">
                    <Dropdown.Toggle variant="" id="dropdown-basic" className='d-flex align-items-center'>
                        <FontAwesomeIcon icon={faBell} className='me-2' />
                        <span className="me-2">
                           Comunicado
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='text-end'>
                        {fetchContenidosData.data?.data?.data?.map((item, index) => {
                                return (
                                     <Dropdown.Item className="text-start text-muted" key={index} onClick={() => handleOpenModal_Detalle({id: item.id})}>{index + 1}. {item.titulo}</Dropdown.Item>
                                )
                            })}
                       <Dropdown.Divider />

                       <Dropdown.Item className="text-center text-muted" onClick={() => {history.push(`/public-comunicados`)}}><FontAwesomeIcon icon={faEye} className='me-2' /> Ver más</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
        
                */}
                {!isModuleAdministrador(modulo_app) &&
                    <span 
                        className={`ms-1 ${isActive ? 'text-primary' : ''}`}
                        onClick={() => history.push('/tickets')} 
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                    >
                        <FontAwesomeIcon icon={faExclamationTriangle} className='me-1' title="Incidencias"/>
                        <span className="d-none d-md-inline" className="ms-1">Incidencias</span> {/* Oculta en pantallas pequeñas */}
                    </span>
                }

                <Dropdown align="end">
                    <Dropdown.Toggle variant="" id="dropdown-basic" className='d-flex align-items-center'>
                        <FontAwesomeIcon icon={faUserCircle} className='me-2' />
                        <span className="me-2">
                            {nombre} {apellidos}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='text-end'>
                        <Dropdown.Item onClick={handleShow}>{Lang('CAMBIAR_CONTRASENIA')}</Dropdown.Item>
                        <Dropdown.ItemText onClick={handleLogout} className='dropdown-item cursor-pointer'>{Lang('SALIR')}</Dropdown.ItemText>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <Modal show={showModalChangePassword} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>{Lang('CAMBIAR_CONTRASENIA')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <Form className='row'>
                        <Form.Group className="col-12 col-lg-6 mb-3">
                            <InputText
                                label='Contraseña antigua'
                                type='password'
                                className=''
                                name='old_password'
                                value={formValuesPassword.old_password}
                                onChange={handleInputChangePassword}
                                validation={valuesValidPassword}
                                fetchData={fetchPasswordData}
                            />
                        </Form.Group>

                        <div className="col-12"></div>

                        <Form.Group className="col-12 col-lg-6 mb-3">
                            <InputText
                                label='Contraseña nueva'
                                type='password'
                                className=''
                                name='new_password'
                                value={formValuesPassword.new_password}
                                onChange={handleInputChangePassword}
                                validation={valuesValidPassword}
                                fetchData={fetchPasswordData}
                            />
                        </Form.Group>

                        <Form.Group className="col-12 col-lg-6 mb-3">
                            <InputText
                                label='Confirmación de contraseña nueva'
                                type='password'
                                className=''
                                name='new_password_confirmation'
                                value={formValuesPassword.new_password_confirmation}
                                onChange={handleInputChangePassword}
                                validation={valuesValidPassword}
                                fetchData={fetchPasswordData}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Salir
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCambiarPassword} className='ms-2' disabled={fetchPasswordData.loading}>
                        {
                            fetchPasswordData.loading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                                : ''
                        }
                        <FontAwesomeIcon icon={faSave} className='me-1' /> Cambiar
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </section>

         <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
            <div className="py-0 text-center">
                <h5 className='mb-4 fw-normal'>
                    <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                </h5>
                <p className='mb-0'>Los datos han sido guardados correctamente
                </p>
            </div>
        </ModalStatus>
         <ModalDetalle
            data={dataModal_Detalle}
            handleCloseModal={handleCloseModal_Detalle}
            //fechDataNotificaciones={fetchNotificaciones}
            btnHidden={true}
            />
        </>
    )
}
