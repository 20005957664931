import React, { useContext, useEffect } from 'react'
import { faHome, faBook, faCog, faWrench } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Breadcrumb, ListGroup, Alert, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge';
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { isModuleConsultor, isModuleOrganizacion } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { PaginationT } from '../../components/PaginationT/PaginationT'
import { useForm } from '../../hooks/useForm'
import { Loader } from '../../components/Loader/Loader'
import {ModalEstadoDapcons} from './ModalEstadoDapcons'
import {ModalRenovacion} from './ModalRenovacion'
import { useModalData } from '../../hooks/useModalData';
import { ModalConfirmarPagoEmpresa } from './ModalConfirmarPagoEmpresa'
import { ModalConfirmarPagoDapcons } from './ModalConfirmarPagoDapcons'

export const DashboardAdmin = () => {
    const [dataModal_Estado, handleOpenModal_Estado, handleCloseModal_Estado] = useModalData()
    const [dataModal_Renovacion, handleOpenModal_Renovacion, handleCloseModal_Renovacion] = useModalData()
    const [dataModal_ConfirmOrg, handleOpenModal_ConfirmOrg, handleCloseModal_ConfirmOrg] = useModalData()
    const [dataModal_ConfirmDaps, handleOpenModal_ConfirmDaps, handleCloseModal_ConfirmDaps] = useModalData()
    const history = useHistory()
    const [fetchConsolidado, fetchConsolidadoData] = useAxios('get', '/panel/consolidado')
    //const [fetchOrganizacion, fetchOrganizacionData] = useAxios('get', '/cuotas-anuales')

    // useEffect(() => {

    //     fetchOrganizacion({
    //         params: {
    //             pagado: false
    //         }
    //     })

    // }, [])

    /*useEffect(() => {

        console.log('fetchOrganizacionData', fetchOrganizacionData);

    }, [fetchOrganizacionData]);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        pagado: false,
        order_by: 'year desc',
    });


    useEffect(() => {

        fetchOrganizacion({
            params: {
                page: formValuesSearch.page,
                pagado: formValuesSearch.pagado,
                order_by: formValuesSearch.order_by
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order_by])*/

    useEffect(() => {
        fetchConsolidado()
    }, [])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Inicio
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="col-12 animate__animated animate__fadeInUp">
                {/* <Alert variant='warning'>
                    No esta definido el logotipo del consultor, <Alert.Link onClick={() => history.push('/mis-datos')}>haga click aquí</Alert.Link> para añadirlo
                </Alert> */}
                {fetchConsolidadoData.loading &&
                    <div className="MainInner shadow-sm mb-4">
                        <div className="d-flex justify-content-between">
                            <div className='flex-grow-1'>
                                <Loader/>
                            </div>
                        </div>
                    </div> 
                }   
                <div className="row">
                 {(fetchConsolidadoData.data?.data?.data?.organizaciones?.total > 0 || fetchConsolidadoData.data?.data?.data?.organizaciones_transferencia?.total > 0 || fetchConsolidadoData.data?.data?.data?.renovacion?.total > 0) &&
                    <div className="col-12 col-lg-6">
                     {fetchConsolidadoData.data?.data?.data?.organizaciones?.total > 0 &&
                        <div className="MainInner shadow-sm mb-4">
                            <div className="d-flex justify-content-between">
                                <div className='flex-grow-1'>
                                    <h5 className='fs-105 border-bottom pb-3'>Empresas pendientes de pago <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.organizaciones?.total}</Badge></h5>
                                        <Table striped bordered responsive className="tableMain mt-3">
                                          <thead>
                                            <tr className="border">
                                              <th className="text-center">ID</th>
                                              <th className="text-center">Nombre</th>
                                              <th className="text-center">Fecha creación</th>
                                              <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {fetchConsolidadoData.data?.data?.data?.organizaciones?.data.map((row, index) => (
                                              <tr className="border text-center" key={index}>
                                                <td>{row.org_id}</td>
                                                <td className="text-start">
                                                    <span><b>Nombre:</b> {row.org_nombre}</span><br/>
                                                    <span><b>Email:</b> {row.org_email}</span><br/>
                                                    <span><b>CIF:</b> {row.org_cif}</span>
                                                </td>
                                                <td className="text-center">{new Date(row.fecha_creacion)?.toLocaleDateString()}</td>
                                                <td className="text-center">
                                                     <OverlayTrigger
                                                        key={row.org_id + '1'}
                                                        placement={'top'}
                                                        overlay={
                                                            <Tooltip id={`tooltip-${row.org_id}`}>
                                                                Ver mas
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button variant="transparent" size="sm" className="text-dark" 
                                                            onClick={() => history.push(`/empresas/${row.org_cif}`)}
                                                        >
                                                            <FontAwesomeIcon icon={faBook} />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                </div>
                            </div>
                        </div>
                        }

                        {/*AQUI VA LAS EMPRESAS PENDIENTE POR TRANSFERENCIAS*/}
                        {fetchConsolidadoData.data?.data?.data?.organizaciones_transferencia?.total > 0 &&
                         <div className="MainInner shadow-sm mb-4">
                            <div className="d-flex justify-content-between">
                                <div className='flex-grow-1'>
                                    <h5 className='fs-105 border-bottom pb-3'>Empresas Pendiente de transferencia <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.organizaciones_transferencia?.total}</Badge></h5>
                                        <Table striped bordered responsive className="tableMain mt-3">
                                          <thead>
                                            <tr className="border">
                                              <th className="text-center">ID</th>
                                              <th className="text-center">Nombre</th>
                                              <th className="text-center">Fecha creación</th>
                                              <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {fetchConsolidadoData.data?.data?.data?.organizaciones_transferencia?.data.map((row, index) => (
                                              <tr className="border text-center" key={index}>
                                                <td>{row.org_id}</td>
                                                <td className="text-start">
                                                    <span><b>Nombre:</b> {row.org_nombre}</span><br/>
                                                    <span><b>Email:</b> {row.org_email}</span><br/>
                                                    <span><b>CIF:</b> {row.org_cif}</span>
                                                </td>
                                                <td className="text-center">{new Date(row.fecha_creacion)?.toLocaleDateString()}</td>
                                                <td className="text-center">
                                                    <Button variant="primary" size="sm" 
                                                        onClick={() => {
                                                                    handleOpenModal_ConfirmOrg({
                                                                        id: row.org_id,
                                                                        cif: row.org_cif
                                                                    })
                                                                }}
                                                    >
                                                        Confirmar pago
                                                    </Button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        }
                        {/*Fin de empresas pendientes*/}
                        {fetchConsolidadoData.data?.data?.data?.renovacion?.total > 0 &&
                        <div className="MainInner shadow-sm mb-4">
                        <div className="d-flex justify-content-between">
                        <div className='flex-grow-1'>
                            <h5 className='fs-105 border-bottom pb-3'>Empresas por renovación <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.renovacion?.total}</Badge></h5>
                                    <Table striped responsive bordered className="tableMain mt-3">
                                      <thead>
                                        <tr className="border">
                                          <th className="text-center">ID</th>
                                          <th className="text-center">Nombre</th>
                                          <th className="text-center">Fecha Ultima Renovación</th>
                                          <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {fetchConsolidadoData.data?.data?.data?.renovacion?.data?.map((row, index) => (
                                          <tr className="border text-center" key={index}>
                                            <td>{row.org_id}</td>
                                            <td className="text-start">
                                                <span><b>Nombre:</b> {row.org_nombre}</span><br/>
                                                <span><b>Email:</b> {row.org_email}</span><br/>
                                                <span><b>CIF:</b> {row.org_cif}</span>
                                            </td>
                                            <td className="text-center">{row.fecha_ultima_renovacion}</td>
                                            <td className="text-center">

                                                <Button variant="transparent" size="sm" className='text-primary-dapcons' onClick={() => {
                                                        handleOpenModal_Renovacion({
                                                            action: 'CREATE',
                                                            id: row.org_id,
                                                            org_cif: row.org_cif,
                                                        })
                                                    }}
                                                 >
                                                    <FontAwesomeIcon icon={faWrench} />
                                                </Button>

                                                 <OverlayTrigger
                                                    key={row.org_id + '1'}
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${row.org_id}`}>
                                                            Ver mas
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button variant="transparent" size="sm" className='text-dark'
                                                        onClick={() => history.push(`/empresas/${row.org_cif}`)}
                                                    >
                                                        <FontAwesomeIcon icon={faBook} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                       } 
                    </div>
                    }

                    {(fetchConsolidadoData.data?.data?.data?.dapcons?.total > 0 || fetchConsolidadoData.data?.data?.data?.dapcons_transferencia?.total > 0 || fetchConsolidadoData.data?.data?.data?.tickets?.total > 0) &&

                    <div className="col-12 col-lg-6">
                    {fetchConsolidadoData.data?.data?.data?.dapcons?.total > 0 &&
                    <div className="MainInner shadow-sm mb-4">
                        <div className="d-flex justify-content-between">
                            <div className='flex-grow-1'>
                                <h5 className='fs-105 border-bottom pb-3'>Dapcons caducados y pendiente de caducar <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.dapcons?.total}</Badge></h5>
                                    <Table striped bordered responsive className="tableMain mt-3">
                                              <thead>
                                                <tr className="border">
                                                  <th className="text-center">Código</th>
                                                  <th className="text-center">Fecha inicio</th>
                                                  <th className="text-center">Fecha Fin</th>
                                                  <th className="text-center">Organizacion</th>
                                                  <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {fetchConsolidadoData.data?.data?.data?.dapcons?.data.map((row, index) => (
                                                  <tr className="border text-center" key={index}>
                                                    <td>{row.dapcons_codigo}</td>
                                                    <td className="text-center">{row.dapcons_valido_desde}</td>
                                                    <td className="text-center">{row.dapcons_valido_hasta}</td>
                                                    <td className="text-center">
                                                         {row.organizacion.org_nombre}
                                                    </td>
                                                    <td className="text-center">
                                                        <OverlayTrigger
                                                            key={index + 1}
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${index + 1}`}>
                                                                    Cambiar estado Dapcons
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant="transparent" size="sm" className='text-primary-dapcons' onClick={() => {
                                                                    handleOpenModal_Estado({
                                                                        action: 'CREATE',
                                                                        id: row.id,
                                                                        codigo: row.dapcons_codigo
                                                                    })
                                                                }}
                                                             >
                                                                <FontAwesomeIcon icon={faWrench} />
                                                            </Button>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            key={row.id + '1'}
                                                            placement={'top'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${row.id}`}>
                                                                    Ver Dapcons
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant="transparent" size="sm" className='text-dark'
                                                                onClick={() => history.push(`/dAPcons/${row.id}/1`)}
                                                            >
                                                                <FontAwesomeIcon icon={faBook} />
                                                            </Button>
                                                        </OverlayTrigger>

                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </Table>      
                                        </div>
                                     </div>
                                 </div>
                                }

                                 {/*AQUI VA LAS DAPCONS PENDIENTE POR TRANSFERENCIAS*/}
                                {fetchConsolidadoData.data?.data?.data?.dapcons_transferencia?.total > 0 &&
                                 <div className="MainInner shadow-sm mb-4">
                                    <div className="d-flex justify-content-between">
                                        <div className='flex-grow-1'>
                                            <h5 className='fs-105 border-bottom pb-3'>Dapcons pendientes de transferencia <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.dapcons_transferencia?.total}</Badge></h5>
                                                <Table striped bordered responsive className="tableMain mt-3">
                                                          <thead>
                                                            <tr className="border">
                                                              <th className="text-center">N° Dapcons</th>
                                                              <th className="text-center">Organizacion</th>
                                                              <th className="text-center">Creación</th>
                                                              <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {fetchConsolidadoData.data?.data?.data?.dapcons_transferencia?.data.map((row, index) => (
                                                              <tr className="border text-center" key={index}>
                                                                <td>N° {row.id}</td>
                                                                <td className="text-center">
                                                                     {row.organizacion.org_nombre}
                                                                </td>
                                                                <td className="text-center">
                                                                    {row.dapcons_fecha_creacion}
                                                                </td>    
                                                                <td className="text-center">
                                                                    <Button variant="primary" size="sm" 
                                                                        onClick={() => {
                                                                                    handleOpenModal_ConfirmDaps({
                                                                                        id: row.id,
                                                                                    })
                                                                                }}
                                                                    >
                                                                        Confirmar pago
                                                                    </Button>
                                                                </td>
                                                              </tr>
                                                            ))}
                                                          </tbody>
                                                        </Table>     
                                                    </div>
                                                 </div>
                                             </div>
                                }       
                                 {/*AQUI VA LAS DAPCONS PENDIENTE POR TRANSFERENCIAS*/}

                                {fetchConsolidadoData.data?.data?.data?.tickets?.total > 0 &&
                                 <div className="MainInner shadow-sm mb-4">
                                    <div className="d-flex justify-content-between">
                                        <div className='flex-grow-1'>
                                            <h5 className='fs-105 border-bottom pb-3'>Tickets mensajes <Badge bg="primary-dapcons">{fetchConsolidadoData.data?.data?.data?.tickets?.total}</Badge></h5>
                                                <Table striped bordered responsive className="tableMain mt-3">
                                                  <thead>
                                                    <tr className="border">
                                                      <th className="text-center">Tickets</th>
                                                      <th className="text-center">Mensajes</th>
                                                      <th className="text-center">Creación</th>
                                                      <th className="text-center"> <FontAwesomeIcon icon={faCog} /></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {fetchConsolidadoData.data?.data?.data?.tickets?.data.map((row, index) => (
                                                      <tr className="border text-center" key={index}>
                                                        <td>N° {row.asunto}</td>
                                                        <td className="text-center">
                                                             {row.mensaje}
                                                        </td>
                                                        <td className="text-center">
                                                            {new Date(row.created_at)?.toLocaleDateString()}
                                                        </td>    
                                                        <td className="text-center">
                                                            <Button variant="primary" size="sm" 
                                                                onClick={() => {
                                                                     history.push('/tickets/'+row.ticket_id);
                                                                }}
                                                             >
                                                                Ver Mensaje
                                                            </Button>
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </tbody>
                                                </Table>     
                                            </div>
                                     </div>
                                 </div>
                                 }   

                               </div> 
                              } 
                            </div>
                        </div>

                        <ModalEstadoDapcons
                            data={dataModal_Estado}
                            handleCloseModal={handleCloseModal_Estado}
                            fetchConsolidado={fetchConsolidado}
                        />

                        <ModalRenovacion
                            data={dataModal_Renovacion}
                            handleCloseModal={handleCloseModal_Renovacion}
                            fetchConsolidado={fetchConsolidado}
                        />

                        <ModalConfirmarPagoEmpresa
                            data={dataModal_ConfirmOrg}
                            handleCloseModal={handleCloseModal_ConfirmOrg}
                            fetchConsolidado={fetchConsolidado}
                        />

                        <ModalConfirmarPagoDapcons
                            data={dataModal_ConfirmDaps}
                            handleCloseModal={handleCloseModal_ConfirmDaps}
                            fetchConsolidado={fetchConsolidado}
                        />

        </div>
    )
}
