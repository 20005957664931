import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../../hooks/useAxios';
import { Loader } from '../../../../components/Loader/Loader';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputText, InputSelect} from '../../../../components/Inputs';
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import InputImage from '../../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';

export const ModalEmail = ({ data, handleCloseModal}) => {
    const location = useLocation()
    const history = useHistory();

    const [fetchContenido, fetchContenidoData] = useAxios('GET', `/contenidos-web/plantilla-email`)
    const [fetchPostEmail, fetchPostEmailData] = useAxios('POST', `/contenidos-web/enviar-email`)
    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)
    const [formContenido, 
        handleInputChangeContenido, 
        handleFormChangeContenido, 
        handleResetContenido] = useForm({
            perfil: [],
            tipo_empresa: '',
            asunto: '',
            mensaje: ''
        })

    const [valuesValidDocumento, handleVerifiedValuesDocumento, handleResetValuesDocumento] = useValidator({
        asunto: { isOk: true, msgError: [`Ingrese un asunto`] },
        mensaje: { isOk: true, msgError: [`Ingrese un Mensaje`] },
    })    

    const handleSubmitEmail = () => {
        if (handleVerifiedValuesDocumento(formContenido)) {
            fetchPostEmail({
                body: {
                    ...formContenido,
                }
            })
        }
    }

    useEffect(() => {
        if (fetchPostEmailData?.data?.data?.status === 'Success') {
            handleCloseModal()
        }
    }, [fetchPostEmailData.data])

    useEffect(() => {
        if(data.value){
            fetchParameters({
                body: [
                    {
                        "param": "tipo_empresa",
                        "locale": 1,
                        "active": true
                    }
                ]
            });
            fetchContenido({
                id: data?.id
            })
        }else{
            handleResetContenido()
        }
    },[data.value])

     useEffect(() => {
        if (fetchContenidoData?.data?.data?.data) {
            const data = fetchContenidoData?.data?.data?.data;
            const perfil = data?.perfil || [];
            handleFormChangeContenido({...formContenido, perfil: perfil, asunto: data.plantilla.asunto, mensaje: data.plantilla.template, tipo_empresa: data.tipo_empresa})
        }
    }, [fetchContenidoData.data])

    const handleChange = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            handleFormChangeContenido({
                ...formContenido,
                perfil: [...formContenido.perfil, parseInt(value)],
            });
        } else {
            const items = formContenido.perfil.filter((item) => item !== parseInt(value));
            handleFormChangeContenido({
                ...formContenido,
                perfil: [...items],
            });
        }
    }; 

    const handleEditorChange = (content, editor) => {
        const target = {
            name: 'mensaje',
            value: content,
        }
        handleInputChangeContenido({ target })
    }
    return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Envio de email</Modal.Title>
            </Modal.Header>
            {(fetchParametersData.loading || fetchContenidoData.loading) ? <Loader /> : <>
                <Modal.Body className='px-4 py-4'>
                <div className="row">
                     <Form.Group className="col-12 col-lg-7">
                            <Form.Label className="fw-500 form-label">Perfiles de usuario:</Form.Label>
                            <div className="col-12 mt-1">
                            {[
                                {
                                    id: 3, nombre: 'Consultor'
                                },
                                {
                                    id: 4, nombre: 'Verificador'
                                },
                                {
                                    id: 5, nombre: 'Organización'
                                },
                            ].map((check, index) => ( 
                              <div className="d-inline-block me-4" key={index}> 
                                  <Form.Check
                                        type="checkbox"
                                        id={`${check.id}`}
                                        label={check.nombre}
                                        value={check.id}
                                        checked={formContenido.perfil.some((item) => item === check.id)}
                                        onChange={handleChange}
                                    />
                                </div>
                             ))}    
                            </div>
                        </Form.Group>

                        {formContenido.perfil.includes(5) &&
                            <Form.Group className="mb-3 col-12 col-lg-5"> 
                                <InputSelect
                                    label='Tipo de Empresa:'
                                    name='tipo_empresa'
                                    values={
                                        fetchParametersData.data?.data?.data?.tipo_empresa?.map((item, index) => {
                                            return {
                                               id: item.param_id,
                                               nombre: item.pt_texto
                                            }
                                        })
                                      }
                                    value={formContenido.tipo_empresa}
                                    onChange={handleInputChangeContenido}
                                    validation={valuesValidDocumento}
                                    fetchData={fetchPostEmailData}
                                />
                            </Form.Group>
                        }
                </div>  
                <div className="row d-flex justify-content-center align-items-center ">
                    <div className="col-lg-12 mb-4">

                      <Form.Group className="mb-3 col-12 col-sm-12 mt-2 col-xl-12">
                        <InputText
                            label='Asunto:'
                            className=''
                            name='asunto'
                            placeholder="Ingrese un título"
                            value={formContenido.asunto}
                            onChange={handleInputChangeContenido}
                            validation={valuesValidDocumento}
                            //fetchData={fetchCrearDocumentoData}
                        />
                    </Form.Group>

                     <Form.Group className="mb-3 col-12 col-sm-12 mt-2 col-xl-12">
                        <Form.Label className="fw-500 form-label">Contenido email:</Form.Label>
                        <Editor
                            className="border-0"
                            init={{
                                    skin: false,
                                    branding: false,
                                    content_css: false,
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        'link image',
                                        'table paste',
                                        'code'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help code | link'
                                }}
                            value={formContenido.mensaje}
                            onEditorChange={handleEditorChange}
                        />
                        </Form.Group>
                    </div>
                 </div>   
                </Modal.Body>
                <Modal.Footer>

                     <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleSubmitEmail} fetchDataLoading={[fetchPostEmailData.loading]}
                        value='Enviar Email' icon={<FontAwesomeIcon icon={faEnvelope} className='me-1' />}
                    />


                    <Button variant="default"  onClick={handleCloseModal}>
                        Salir
                    </Button>
                </Modal.Footer>
            </>
         }
        </Modal>
    )
}
