import { faHome, faSave, faFolder, faEdit, faPlus, faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Breadcrumb, Button, Form, ListGroup, Modal, Tab, Tabs } from 'react-bootstrap'
import { AuthContext } from '../../auth/AuthContext'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputSelect, InputText } from '../../components/Inputs'
import { Loader } from '../../components/Loader/Loader'
import { ModalPrompt } from '../../components/ModalStatusServer/ModalPrompt'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'
import { ModalStatusError } from '../../components/ModalStatusServer/ModalStatusError'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { isAdmin, isCaateeb } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { useForm } from '../../hooks/useForm'
import './Parametros.css'
import TreeItem from '../../components/TreeItem/TreeItem'

export const Parametros = ({ namePage }) => {

    const { user: { perfil } } = useContext(AuthContext);

    const [fetchParametros, fetchParametrosData] = useAxios('get', '/parametros')

    const [fetchParametrosChild, fetchParametrosChildData] = useAxios('get', '/parametros')

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    const [expandedItemId, setExpandedItemId] = useState(null);

    const [tituloForm, setTituloForm] = useState({
        type: 'default',
        value: 'Parámetro'
    })

    useEffect(() => {
        fetchParametros({
            params: {
                    arbol: false
                }
        })

        fetchIdiomas()
    }, [])

    const [formParam, handleInputChangeParam, handleFormChangeParam, handleResetParam] = useForm({
        expand: false,
        activo: true,
        param_id: '',
        param_order: '',
        param_permiso: '',
        param_padre_id: '',
        param_admite_hijos: false,
        param_textos: [],
    })

    useEffect(() => {
        console.log(formParam)
    }, [formParam])

    useEffect(() => {
        if (fetchParametrosData.data?.status === 200) {
            const arrayParams = fetchParametrosData.data?.data?.data;
            const arrayParams2 = arrayParams.map(item => {
                return {
                    type: item.param_admite_hijos ? "folder" : 'file',
                    expand: false,
                    param_nombre: item.pt_texto || 'Sin nombre',
                    param_admite_hijos: item.param_admite_hijos || false,
                    activo: item.activo || false,
                    param_id: item.param_id,
                    param_order: item.param_order,
                    param_padre_id: '',
                    param_permiso: item.param_permiso,
                    param_textos: item.param_textos || [],
                    child: []
                }
            })
            setTree(arrayParams2)
        }
    }, [fetchParametrosData])

    const updateTreeWithChildren = (tree, itemId, childData) => {
        return tree.map(item => {
            if (item.param_id === itemId) {
                return {
                    ...item,
                    child: childData,
                    expand: true
                };
            }
            if (item.child && item.child.length > 0) {
                return {
                    ...item,
                    child: updateTreeWithChildren(item.child, itemId, childData)
                };
            }
            return item;
        });
    };

    useEffect(() => {
        if (fetchParametrosChildData.data?.status === 200) {
           const arrayParams = fetchParametrosChildData.data?.data?.data[expandedItemId].parametros     
           const hijos = arrayParams.map(item => {
                /*const texto = item && item.param_textos && item.param_textos.length > 0
                ? item.param_textos[0].pt_texto
                : 'Sin nombre';*/
                return {
                    type: item.param_admite_hijos ? "folder" : 'file',
                    expand: false,
                    param_nombre: item.pt_texto,
                    param_admite_hijos: item.param_admite_hijos || false,
                    activo: item.activo || false,
                    param_permiso: item.param_permiso,
                    param_id: item.param_id,
                    param_order: item.param_order,
                    param_padre_id: expandedItemId,
                    param_textos: item.param_textos || [],
                    child: []
                }
            })
            const updatedTree = updateTreeWithChildren(tree, expandedItemId, hijos);
            setTree(updatedTree);
            setExpandedItemId(null);
        }
    }, [fetchParametrosChildData])

    const [tree, setTree] = useState([])

    const [pathIndices, setPathIndices] = useState({
        path: '',
        id: ''
    })

    const [item, setItem] = useState(null)

    const handleClickExpandTree = (item, path) => {
        if (!item.expand) {
            setExpandedItemId(item.param_id);
            fetchParametrosChild({
                id: item.param_id,
                params: {
                    arbol: false
                }
            })
        } else {
            item.expand = false;
            setTree([...tree]);
        }
    }

    const [fetchEditarParametro, fetchEditarParametroData, resetFetchEditarParametro] = useAxios('patch', `/parametros/actualizar`)

    const handleClickEditParam = (item) => {
        console.log(item)

        const formParamTextosTemp = [...formParam.param_textos]

        console.log(formParamTextosTemp)

        for (let i = 0; i < formParamTextosTemp.length; i++) {

            const itemExtraido = item.param_textos?.find(item2 => item2.pt_idioma_id === item.param_textos?.[i]?.pt_idioma_id) || {}
            console.log('itemExtraido', itemExtraido)

            formParamTextosTemp[i]['pt_texto'] = itemExtraido.pt_texto || ''
            formParamTextosTemp[i]['pt_texto2'] = itemExtraido.pt_texto2 || ''
            formParamTextosTemp[i]['pt_descripcion'] = itemExtraido.pt_descripcion || ''
        }

        handleFormChangeParam({
            activo: item.activo || false,
            param_admite_hijos: item.param_admite_hijos || false,
            param_id: item.param_id,
            param_order: item.param_order,
            param_permiso: item.param_permiso,
            param_padre_id: item.param_padre_id,
            param_textos: formParamTextosTemp,
        })

        setTituloForm({
            type: 'edit',
            value: <span>Editar parámetro: <span className="fw-normal">{item.param_nombre}</span></span>
        })

        //window.scrollTo({top: 0, behavior: 'smooth'});

    }

    const handleSubmitEditParam = () => {

        const formParamTemp2 = { ...formParam }

        const param_textosClean = formParamTemp2.param_textos?.map(item => {

            return {
                pt_idioma_id: item.pt_idioma_id,
                pt_texto: item.pt_texto,
                pt_texto2: item.pt_texto2,
                pt_descripcion: item.pt_descripcion,
            }
        })

        const formParamTemp = {
            ...formParamTemp2,
            param_order: Number(formParamTemp2.param_order),
            param_permiso: Number(formParamTemp2.param_permiso),
            parametros_textos: param_textosClean,

        }

        delete formParamTemp['param_textos']

        fetchEditarParametro({
            body: {
                ...formParamTemp,

            }
        })
    }

    const [fetchCrearParametro, fetchCrearParametroData, resetFetchCrearParametro] = useAxios('post', `/parametros/crear`)


    const handleClickCreateParam = (item) => {

        // Reset manual, no podemos poner el textos en array vacio, debe tener los del idioma

        console.log(item)

        const textosVacios = formParam.param_textos?.map(item2 => {
            return {
                pt_idioma_id: item2.pt_idioma_id,
                nombre: item2.nombre,
                pt_texto: '',
                pt_texto2: '',
                pt_descripcion: '',
            }
        })

        handleFormChangeParam({
            param_id: '',
            param_order: '',
            param_padre_id: item.param_id,
            activo: true,
            param_permiso: '',
            param_textos: textosVacios,
        })

        setTituloForm({
            type: 'create',
            value: <span>Crear parámetro en: <span className="fw-normal">{item.param_nombre}</span></span>
        })
        //window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleResetParamsMod = () => {

        console.log(formParam)

        const textosVacios = formParam.param_textos?.map(item2 => {
            return {
                pt_idioma_id: item2.pt_idioma_id,
                nombre: item2.nombre,
                pt_texto: '',
                pt_texto2: '',
                pt_descripcion: '',
            }
        })

        console.log(textosVacios)

        handleFormChangeParam({
            param_id: '',
            param_order: '',
            param_padre_id: '',
            activo: true,
            param_permiso: '',
            param_textos: textosVacios,
        })
    }

    const handleSubmitCreateParam = () => {

        const formParamTemp2 = { ...formParam }

        const param_textosClean = formParamTemp2.param_textos?.map(item => {

            return {
                pt_idioma_id: item.pt_idioma_id,
                pt_texto: item.pt_texto,
                pt_texto2: item.pt_texto2,
                pt_descripcion: item.pt_descripcion,
            }
        })

        console.log(formParam)

        const formParamTemp = {
            ...formParamTemp2,
            param_order: Number(formParamTemp2.param_order),
            param_permiso: Number(formParamTemp2.param_permiso),
            parametros_textos: param_textosClean,
        }

        delete formParamTemp['param_textos']

        fetchCrearParametro({
            body: {
                ...formParamTemp
            }
        })
    }

    useEffect(() => {

        if (fetchIdiomasData.data?.status === 200) {

            const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                return {
                    pt_idioma_id: item.id,
                    nombre: `${item.idioma_nombre}`
                }
            })

            for (let i = 0; i < listTemp.length; i++) {

                const itemExtraido = formParam.param_textos?.find(item => item.pt_idioma_id === listTemp[i].pt_idioma_id) || {}
                console.log('itemExtraido', itemExtraido)

                listTemp[i]['pt_texto'] = itemExtraido.pt_texto || ''
                listTemp[i]['pt_texto2'] = itemExtraido.pt_texto2 || ''
                listTemp[i]['pt_descripcion'] = itemExtraido.pt_descripcion || ''
            }
            console.log('itemExtraido', listTemp)
            handleFormChangeParam({
                ...formParam,
                param_textos: listTemp,
            })
        }

    }, [fetchIdiomasData])


    const handleChangeInputTextos = (e, index) => {

        // console.log(e.target.name, e.target.value)

        const textosTemp = [...formParam.param_textos]

        textosTemp[index][e.target.name] = e.target.value

        handleFormChangeParam({
            ...formParam,
            param_textos: textosTemp
        })
    }

    const handleChangeAdmiteHijos = (e) => {

        handleFormChangeParam({
            ...formParam,
            param_admite_hijos: e.target.checked ? true : false,
        })
    }

    // const handleChangeActivo = (e) => {

    //     handleFormChangeParam({
    //         ...formParam,
    //         activo: e.target.checked ? true : false,
    //     })
    // }

    const [showConfirmacion, setShowConfirmacion] = useState({
        value: false,
        index: -1
    })

    const [fetchEliminarParametro, fetchEliminarParametroData, resetFetchEliminarParametro] = useAxios('patch', `/parametros`)

    const handleSubmitEliminarParam = () => {

        fetchEliminarParametro({
            id: formParam.param_id + '/cambiar-estado-activo',
            body: {
                activo: false,
            }
        })
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);


    useEffect(() => {

        if (fetchEditarParametroData.data?.status === 201 ||
            fetchCrearParametroData.data?.status === 201 ||
            fetchEliminarParametroData.data?.status === 201) {
            fetchParametros()
            handleResetParamsMod()
            setShowSuccessEdit(true)
            setShowConfirmacion({ value: false, index: -1 })
            resetFetchEditarParametro()
            resetFetchCrearParametro()
            resetFetchEliminarParametro()
        }

    }, [fetchEditarParametroData, fetchCrearParametroData, fetchEliminarParametroData])

    const handleClickCrearParamPadre = () => {

        handleResetParamsMod()

        setTituloForm({
            type: 'create',
            value: 'Nuevo Parámetro'
        })
    }
    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="col-xl-7">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                    <div className="d-flex justify-content-between mb-2">
                        <div className='flex-grow-1'>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className='mb-0'>Parámetros</h5>
                                {
                                    isAdmin(perfil) && <Button size='' variant='primary' onClick={handleClickCrearParamPadre}>
                                        Nuevo parametro
                                    </Button>
                                }

                            </div>
                            <div className={`Tree mt-3`}>
                             { fetchParametrosData.loading ?
                                    <div className='Layer-loading d-flex align-items-center justify-content-center position-relative bg-white'>
                                        <Loader />
                                    </div>
                                :
                                tree.length > 0 ?
                                    tree.map((item, index) => {
                                        return <TreeItem
                                                key={index}
                                                item={item}
                                                handleClickExpandTree={handleClickExpandTree}
                                                handleClickEditParam={handleClickEditParam}
                                                handleClickCreateParam={handleClickCreateParam}
                                                fetchParametrosChildData={fetchParametrosChildData}
                                                expandedItemId={expandedItemId}
                                        />
                                     })  
                                 : <div className='Layer-loading d-flex align-items-center justify-content-center position-relative bg-white'>
                                        No hay resultados para mostrar
                                    </div>
                             }   
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-5 mt-4 mt-xl-0">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp sticky-top mx-auto d-block">
                    <div className="d-flex justify-content-between">
                        <div className='flex-grow-1 row'>
                            <h5 className='h5 mb-3'>{tituloForm.value}</h5>
                             {
                                isAdmin(perfil) &&
                                <Form.Group className="mb-3 col-12">
                                    <InputText
                                        label='ID *'
                                        className=''
                                        name='param_id'
                                        value={formParam.param_id}
                                        onChange={handleInputChangeParam}
                                        disabled={tituloForm.type == 'edit' ? true : false}
                                        // validation={valuesValidRegistroAnalista}
                                        // fetchData={fetchRegistroAnalistaData}
                                    />
                                </Form.Group>
                             }   
                            <Form.Group className="mb-3 col-12">
                                <InputText
                                    label='Orden *'
                                    className=''
                                    name='param_order'
                                    value={formParam.param_order}
                                    onChange={handleInputChangeParam}
                                // validation={valuesValidRegistroAnalista}
                                // fetchData={fetchRegistroAnalistaData}
                                />
                            </Form.Group>

                            {
                                isAdmin(perfil) && <>
                                    <Form.Group className="mb-3 col-12 col-lg-6">
                                        <InputText
                                            label='ID padre *'
                                            className=''
                                            name='param_padre_id'
                                            value={formParam.param_padre_id}
                                            onChange={handleInputChangeParam}
                                        // validation={valuesValidRegistroAnalista}
                                        // fetchData={fetchRegistroAnalistaData}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12 col-lg-6">
                                        <InputSelect
                                            label='Permiso para Caateeb *'
                                            className=''
                                            name='param_permiso'
                                            values={[
                                                { id: 0, nombre: 'Sin permisos' },
                                                { id: 1, nombre: 'Lectura' },
                                                { id: 2, nombre: 'Edición' },
                                                { id: 3, nombre: 'Eliminación' },
                                            ]}
                                            // values={poblaciones}
                                            value={formParam.param_permiso + ''}
                                            onChange={handleInputChangeParam}
                                        />
                                    </Form.Group>
                                    <div className="col-12 col-lg-6 mb-2" key='param_admite_hijos'>
                                        <Form.Check
                                            type='checkbox'
                                            name='param_admite_hijos'
                                            checked={formParam.param_admite_hijos}
                                            onChange={handleChangeAdmiteHijos}
                                            id='param_admite_hijos'
                                            label='Admite parámetros hijos'
                                        />
                                        {/* <MsgInput obj={valuesValidRegistroEmpresa} name='iso14001' /> */}
                                    </div>

                                    <div className="col-12 col-lg-6 mb-2" key='activo'>
                                        <Form.Check
                                            type='checkbox'
                                            name='activo'
                                            checked={formParam.activo}
                                            // onChange={handleChangeActivo}
                                            id='activo'
                                            label='Activo'
                                            disabled
                                        />
                                    </div>
                                </>
                            }

                            <div className='input-multi-tabs mb-3 mt-3'>

                                <Tabs defaultActiveKey={formParam.param_textos?.[0]?.pt_idioma_id} id="tab-findevida" className="mb-3">
                                    {
                                        formParam.param_textos?.map((item, index) => {
                                            return (
                                                <Tab eventKey={item.pt_idioma_id} title={item.nombre} key={index}>
                                                    <Form className='row'>

                                                        <Form.Group className="mb-3 col-12">
                                                            <InputText
                                                                label='Texto 1 *'
                                                                className=''
                                                                name='pt_texto'
                                                                value={formParam.param_textos?.[index].pt_texto}
                                                                onChange={(e) => handleChangeInputTextos(e, index)}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="mb-3 col-12">
                                                            <InputText
                                                                label='Texto 2'
                                                                className=''
                                                                name='pt_texto2'
                                                                value={formParam.param_textos?.[index].pt_texto2}
                                                                onChange={(e) => handleChangeInputTextos(e, index)}
                                                            />
                                                        </Form.Group>

                                                        <Form.Group className="col-12 mb-0" controlId="exampleForm.ControlInput7">
                                                            <InputText
                                                                className=''
                                                                label='Descripción'
                                                                as="textarea"
                                                                rows={4}
                                                                name='pt_descripcion'
                                                                onChange={(e) => handleChangeInputTextos(e, index)}
                                                                value={formParam.param_textos?.[index].pt_descripcion}
                                                            />
                                                        </Form.Group>

                                                    </Form>
                                                </Tab>
                                            )
                                        })
                                    }
                                </Tabs>

                            </div>

                            <div className="col-12">
                                <div className="d-flex justify-content-between">
                                    {
                                        console.log(isAdmin(perfil), isCaateeb(perfil), formParam.param_permiso)
                                    }
                                    {
                                        tituloForm.type === 'edit' && <>
                                            {
                                                (isAdmin(perfil) || (isCaateeb(perfil) && formParam.param_permiso === 3)) && 
                                                <Button variant='danger' type='button' onClick={() => setShowConfirmacion({ value: true, index: formParam.param_id })}>
                                                    <FontAwesomeIcon icon={faTrash} className='me-1' /> Eliminar
                                                </Button>
                                            }
                                            <ButtonSpinner
                                                variant="info" type="submit" className="ms-auto"
                                                handleSubmit={handleSubmitEditParam} fetchDataLoading={[fetchEditarParametroData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </>
                                    }

                                    {
                                        tituloForm.type === 'create' && <ButtonSpinner
                                            variant="info" type="submit" className="ms-auto"
                                            handleSubmit={handleSubmitCreateParam} fetchDataLoading={[fetchCrearParametroData.loading]}
                                            value='Crear' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                        />
                                    }

                                </div>
                            </div>

                            <div className="col-12 text-end">

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ModalPrompt setShowStatus={setShowConfirmacion} showStatus={showConfirmacion}>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mb-4 fw-normal'>
                            Eliminar Parámetro
                        </h5>
                        <p className='mb-0 px-3'>
                            ¿Está seguro que desea eliminar este Parámetro?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => setShowConfirmacion({ value: false, index: -1 })}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="danger" type="submit" className="me-2"
                        handleSubmit={handleSubmitEliminarParam} fetchDataLoading={[fetchEliminarParametroData.loading]}
                        value='Eliminar'
                    />
                </Modal.Footer>
            </ModalPrompt>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchEditarParametroData} />
            <ModalStatusError fetchData={fetchCrearParametroData} />
            <ModalStatusError fetchData={fetchEliminarParametroData} />
        </div>
    )
}
